<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Update Near By
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="CountryPageFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="Description" rules="">
                <quill-editor
                  v-model="CountryPageForm.description"
                  :options="getEditorOptions('description')"
                  ref="descriptionEditor"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Title" label-for="blog-edit-title" class="mb-2">
              <validation-provider #default="{ errors }" name="Title" rules="required">
                <b-form-input id="blog-edit-title" v-model="CountryPageForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="slug" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="slug" rules="required">
                <b-form-input id="blog-edit-slug" v-model="CountryPageForm.slug" @input="removeSpace($event)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Excerpt" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="Excerpt" rules="required">
                <b-form-input id="blog-edit-Excerpt" v-model="CountryPageForm.excerpt" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Country" label-for="blog-edit-category" class="mb-2">
              <validation-provider #default="{ errors }" name="country" rules="required">
                <v-select
                  id="blog-edit-"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="CountryPageForm.country_id"
                  label="name"
                  :options="VisitorList"
                  :reduce="(val) => val.id"
                >
                  <template #no-options="{ search, searching, loading }">
                    No data ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="">
            <div class="border rounded p-2" style="margin:0px">
              <h4 class="mb-1">
                image
              </h4>
              <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="`https://lpcentre.com/${CountryPageForm.image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group label="image" label-for="blog-edit-title" class="mb-2">
                      <validation-provider #default="{ errors }" name="Tab Image " rules="required">
                        <b-form-file
                          ref="refInputE2"
                          v-model="CountryPageForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="Image Caption" label-for="blog-edit-title" class="mb-2">
                      <b-form-input id="alt-image" v-model="CountryPageForm.image_caption" />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="12" class="mt-5">
            <div class="border rounded p-2" style="margin:0px">
              <h4 class="mb-1">
                Card image
              </h4>
              <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                <b-media-aside>
                  <b-img
                    ref="refPreviewE3"
                    :src="`https://lpcentre.com/${CountryPageForm.card_image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group label="image" label-for="blog-edit-title" class="mb-2">
                      <validation-provider #default="{ errors }" name="Card Image " rules="required">
                        <b-form-file
                          ref="refInputE3"
                          v-model="CountryPageForm.card_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-row>
            <b-col md="4">
              <b-form-group label="faq Title" label-for="blog-edit-title" class="mb-2">
                <validation-provider #default="{ errors }" name="faq" rules="">
                  <b-form-input id="blog-edit-title" v-model="CountryPageForm.faq_title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="faq description" label-for="blog-content" class="mb-2">
                <validation-provider #default="{ errors }" name="Details" rules="">
                  <quill-editor
                    v-model="CountryPageForm.faq_description"
                    :options="getEditorOptions('faq_description')"
                    ref="faq_descriptionEditor"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Courses Title" label-for="blog-edit-title" class="mb-2">
                <validation-provider #default="{ errors }" name="ourses" rules="required">
                  <b-form-input id="blog-edit-title" v-model="CountryPageForm.courses_title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Courses description" label-for="blog-content" class="mb-2">
                <validation-provider #default="{ errors }" name="Details" rules="">
                  <quill-editor
                    v-model="CountryPageForm.courses_description"
                    :options="getEditorOptions('courses_description')"
                    ref="courses_descriptionEditor"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Diploma Title" label-for="blog-edit-title" class="mb-2">
                <validation-provider #default="{ errors }" name="ourses" rules="required">
                  <b-form-input id="blog-edit-title" v-model="CountryPageForm.long_courses_title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Diploma description" label-for="blog-content" class="mb-2">
                <validation-provider #default="{ errors }" name="Details" rules="">
                  <quill-editor
                    v-model="CountryPageForm.long_courses_description"
                    :options="getEditorOptions('long_courses_description')"
                    ref="long_courses_descriptionEditor"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Meta Title" label-for="blog-edit-title" class="mb-2">
                <validation-provider #default="{ errors }" name="meta_title" rules="required">
                  <b-form-input id="blog-edit-title" v-model="CountryPageForm.meta_title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Meta Description" label-for="blog-edit-slug" class="mb-2">
                <validation-provider #default="{ errors }" name="In-Link Name" rules="required">
                  <b-form-input id="blog-edit-slug" v-model="CountryPageForm.meta_description" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Meta Keywords" label-for="blog-content" class="mb-2">
                <b-form-tags v-model="CountryPageForm.meta_keywords" />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-50">
              <b-button
                v-if="!isLoading"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="save"
              >
                Save Changes
              </b-button>
              <b-button v-if="isLoading" variant="primary" disabled class="mr-1">
                Loading...
                <b-spinner small />
                <span class="sr-only" />
              </b-button>
            </b-col>
          </b-row>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import vSelect from "vue-select"
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    // const store = useStore();
    const { route } = useRouter()
    const isLoading = ref(false)
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const VisitorList = ref([])
    const Tabimage = ref('media/svg/files/blank-image.svg')

    const form = ref({
      description: '',
      title: '',
      meta_title: '',
      meta_description: '',
      meta_keywords: '',
      keywords: '',
      image: '',
      image_caption: '',
      country_id:'',
      slug:'',
      excerpt:'',
      faq_title:'',
      faq_description:'',
      courses_title:'',
      courses_description:'',
      long_courses_title:'',
      long_courses_description:'',
    })

    store.dispatch("price/getVistior").then((response) => {
      VisitorList.value = response.data.data
    })

    const CountryPageForm = reactive(form)
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )

    const refInputE3 = ref(null)
    const refPreviewE3 = ref(null)

    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )

    const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
      base64 => {
        refPreviewE3.value.src = base64
      },
    )

    const { id } = route.value.params
    const CountryPageFormvalidate = ref()

    store.dispatch('setting/getCountryPage', { id }).then(response => {
      console.log('getCountryPage', response.data.data)
      form.value = response.data.data
      form.value.meta_keywords = response.data.data.meta_keywords.split(',')
    })

    const save = () => {
      const formData = new FormData()
      CountryPageFormvalidate.value.validate().then(success => {
        if (success) {
          isLoading.value = true
          formData.append('description', CountryPageForm.value.description)
          formData.append('meta_title', CountryPageForm.value.meta_title)
          formData.append('card_image', CountryPageForm.value.card_image)
          formData.append('title', CountryPageForm.value.title)
          formData.append('slug', CountryPageForm.value.slug)
          formData.append('country_id', CountryPageForm.value.country_id)
          formData.append('meta_description', CountryPageForm.value.meta_description)
          formData.append('image', CountryPageForm.value.image)
          formData.append('meta_keywords', CountryPageForm.value.meta_keywords)
          formData.append('image_caption', CountryPageForm.value.image_caption)
          formData.append('_method', 'put')
          formData.append('excerpt', CountryPageForm.value.excerpt)
          formData.append('faq_title', CountryPageForm.value.faq_title)
          formData.append('faq_description', CountryPageForm.value.faq_description)
          formData.append('courses_title', CountryPageForm.value.courses_title)
          formData.append('courses_description', CountryPageForm.value.courses_description)
          formData.append('long_courses_title', CountryPageForm.value.long_courses_title)
          formData.append('long_courses_description', CountryPageForm.value.long_courses_description)

          store.dispatch('setting/updateCountryPage', { id, formData })
            .then(response => {
              isLoading.value = false
              Vue.swal({
                title: 'Updated',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    const removeSpace = (target) => {
      CountryPageForm.slug = target.toLowerCase() // LowerCase
        .replace(/\s+/g, "-") // space to -
        .replace(/&/g, ``) // & to and
        .replace(/--/g, `-`)
        .replace(/[.,]/g, `-`)
        .replace(/[_]/g, ``)
        .replace(/[!''""=]/g, ``)
        .replace(/[!?%$^~*#()+]/g, ``)
    }

    const imageHandler = (editorRef) => {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = () => {
        const file = input.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = () => {
          const range = proxy.$refs[editorRef].quill.getSelection()
          const linkValue = `<img src="${reader.result}" alt="Image">`
          proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
        }
      }
    }

    const linkHandler = (editorRef) => {
      const value = prompt('Enter the URL')

      if (value) {
        const range = proxy.$refs[editorRef].quill.getSelection()
        const linkValue = `<a href="${value}" target="_blank" rel="noopener noreferrer">${proxy.$refs[editorRef].quill.getText(range)}</a>`
        proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
      }
    }

    const getEditorOptions = (editorRef) => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
            ['Font', 'FontSize', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'],
            ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
            ['blockquote', 'code-block'],
            ['clean'],
            ['save'],
            ['image', 'link']
          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef)
          }
        }
      }
    })

    return {
      isLoading,
      removeSpace,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      inputImageRendererTab3,
      refInputE3,
      refPreviewE3,
      Tabimage,
      CountryPageForm,
      inputImageRenderer,
      CountryPageFormvalidate,
      VisitorList,
      form,
      id,
      save,
      required,
      email,
      getEditorOptions
    }
  },

  components: {
    BCard,
    vSelect,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormTags,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
